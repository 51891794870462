<template>
  <div class="swipe-box">
    <van-swipe :autoplay="3000">
      <van-swipe-item v-for="(image, index) in images" :key="index" style="height: 414px">
        <img :src="image" style="width: 100%; height: 414px" @click="handleImg(index)" />
      </van-swipe-item>
    </van-swipe>

    <van-image-preview v-model="show" :images="images" @change="onChange">
      <template v-slot:index>第 {{ imgCurIdx }} 页</template>
    </van-image-preview>
  </div>
</template>

<script>
export default {
  props: { images: Array },
  data() {
    return {
      show: false,
      imgCurIdx: 0
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    handleImg(index) {
      this.imgCurIdx = index + 1;
      this.show = true;
    },
    onChange(index) {}
  },
  components: {}
};
</script>
<style scoped lang="less">
.swipe-box {
  background-color: #f5f5f5;
}
</style>
