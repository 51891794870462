<template>
  <div id="container">
    <!-- <NavBar ref="navBarRef" @handleTab="handleTab" /> -->
    <!-- <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/2.png" alt="" class="img-back" />
    <div class="img-menu-box" @click="showMenuDialog = !showMenuDialog">
      <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/3.png" alt="" class="img-menu" />
    </div> -->
    <!-- 菜单弹窗 -->
    <!-- <MenuDialog :showMenuDialog="showMenuDialog" /> -->
    <!-- 商品主图 -->
    <Swipe :images="images" />

    <!-- 商品价格 -->
    <Price :priceObj="priceObj" />
    <!-- 商品标题 -->
    <Title :titleObj="titleObj" />
    <!-- 库存 -->
    <!-- <Stock :stockObj="stockObj" /> -->
    <!-- 优惠券 -->
    <!-- <Coupon :couponObj="couponObj" /> -->
    <!-- 商品规格 -->
    <!-- <Specific :specificObj="specificObj" /> -->
    <!-- 产品详情 -->
    <!-- <ProduceDetail /> -->
    <PayMehtod @changePay="changePay" />
    <!-- 底部 -->
    <Footer @pay="pay" />

    <div class="dialog" v-show="show" v-cloak>
      <div class="dialog-content">
        <p class="p1">请确认支付是否完成</p>
        <p class="p2" @click="completePay">已完成支付</p>
        <p class="p3" @click="againPay">支付遇到问题，重新支付</p>
      </div>
    </div>

    <transition name="fade">
      <div class="loading-dialog" v-show="loading">
        <div class="dialog-overlay"></div>
        <div class="dialog-content">查询中，请稍后...</div>
      </div>
    </transition>

    <transition name="fade">
      <div class="loading-dialog" v-show="showMsg">
        <div class="dialog-overlay"></div>
        <div class="dialog-content">{{ tipMsg }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import Swipe from './components/Swipe.vue';
import Price from './components/Price.vue';
import Title from './components/Title.vue';
import Stock from './components/Stock.vue';
import Coupon from './components/Coupon.vue';
import Specific from './components/Specific.vue';
import ProduceDetail from './components/ProduceDetail.vue';
import MenuDialog from './components/MenuDialog.vue';
import PayMehtod from './components/PayMehtod.vue';
import Footer from '@/views/components/Footer.vue';
import _ from 'lodash';
import Vue from 'vue';
import { Toast } from 'vant';
import { shopWechatPay, getGoodDetail, checkOrder, getALiH5Pay, getconfig, getAppid, shopWechatPay2, getAppid2 } from '@/api/goodsDetail';
import VConsole from 'vconsole';
import $ from 'jquery';
Vue.use(Toast);
export default {
  components: { NavBar, Swipe, Price, Title, Stock, Coupon, Specific, ProduceDetail, Footer, MenuDialog, PayMehtod },
  props: {},
  data() {
    return {
      priceObj: { OriPrice: '', nowPrice: '' }, //价格
      titleObj: { title: '', limitNum: 0 }, //标题
      stockObj: { delLinePrice: '399.00', stock: 1600, salesNum: 10 }, //库存
      couponObj: [
        { overMoney: 500, reduceMoney: 100 },
        { overMoney: 100, reduceMoney: 7 }
      ], //优惠券
      specificObj: {}, //商品规格
      showMenuDialog: false,
      payMethod: 1,
      price: '',
      show: false,
      loading: false,
      showMsg: false,
      tipMsg: '',
      team_id: '123',
      images: [],
      curPageUrl: '',
      authCode: '',
      openid: '',
      WXdata: {},
      // 定义一个变量来存储localStorage的键名
      localStorageKey: 'clickLimiter',
      clickCount: 5, //用户总共可以点击的次数
      limitTime: 5 //用户在多少分钟可以点击
    };
  },
  created() {
    new VConsole();
    if (this.$route.query.orderNo) {
      this.show = true;
    }
  },
  mounted() {
    this.setCache();
    if (this.$route.query.code) {
      this.pay();
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth' // 平滑滚动（可选）
    });
    this.getGoodDetail();
  },
  methods: {
    //记录点击
    recordClick() {
      // 获取当前时间戳和存储的点击数据
      const currentTime = Date.now();
      const clickData = JSON.parse(localStorage.getItem(this.localStorageKey)) || [];

      // 移除过期的点击记录
      const filteredData = clickData.filter((item) => currentTime - item.timestamp < this.limitTime * 60 * 1000);

      // 添加新的点击记录
      filteredData.push({ timestamp: currentTime });

      // 如果点击次数超过5次，则移除最早的记录
      // if (filteredData.length > this.clickCount) {
      //   filteredData.shift();
      // }

      // 将更新后的数据存回localStorage
      localStorage.setItem(this.localStorageKey, JSON.stringify(filteredData));
    },
    // 检查是否达到点击限制
    checkClickLimit() {
      let flag = false; //true 限制  false 不限制
      // 获取存储的点击数据
      const clickData = JSON.parse(localStorage.getItem(this.localStorageKey)) || [];

      // 检查最后一个时间戳是否在五分钟内
      const lastClick = clickData[clickData.length - 1];
      if (lastClick && Date.now() - lastClick.timestamp < this.limitTime * 60 * 1000) {
        // 如果在五分钟内，则检查点击次数是否达到限制
        if (clickData.length > this.clickCount) {
          flag = true;
        } else {
          flag = false;
        }
      } else {
        // 如果最后一个时间戳已过期，重置点击限制
        flag = false;
        localStorage.removeItem(this.localStorageKey); // 可以选择清空localStorage，或者不移除以保持数据（例如，用于统计）
      }
      return flag;
    },
    setCache() {
      let good_id = this.$route.query.good_id;
      let team = this.$route.query.team;
      let sales_id = this.$route.query.sales_id;
      let nonce = this.$route.query.nonce;
      good_id && window.sessionStorage.setItem('good_id', good_id);
      team && window.sessionStorage.setItem('team', team);
      sales_id && window.sessionStorage.setItem('sales_id', sales_id);
      nonce && window.sessionStorage.setItem('nonce', nonce);
    },
    getOpenid() {
      const that = this;
      if (this.openid) {
        return new Promise((resolve, reject) => {
          resolve({
            openid: window.sessionStorage.getItem('openid')
          });
        });
      } else {
        return new Promise((resolve, reject) => {
          $.ajax({
            type: 'POST',
            url: 'https://proceeds.yymijing.com/api/getAccessToken',
            data: { code: that.authCode, appid: window.sessionStorage.getItem('appid') },
            success: function (res) {
              resolve(res);
            }
          });
        });
      }
    },
    //获取时间戳
    timest() {
      var tmp = Date.parse(new Date()).toString();
      tmp = tmp.substr(0, 10);
      return tmp;
    },
    //获取支付的配置接口
    getPayConfig() {
      let urls = window.location.href;
      return new Promise((resolve, reject) => {
        let data = {
          time: this.timest(),
          url: encodeURIComponent(urls)
        };
        $.ajax({
          type: 'POST',
          url: 'https://proceeds.yymijing.com/api/getConfig',
          data: data,
          success: function (res) {
            resolve(res);
          }
        });
      });
    },
    //预支付
    async beforePay() {
      let urls = window.location.href;
      const data = {
        pay_type: this.payMethod,
        good_id: this.$route.query.good_id,
        nonce: window.sessionStorage.getItem('nonce'),
        team_id: window.sessionStorage.getItem('team'),
        sales_id: window.sessionStorage.getItem('sales_id'),
        isWeChat: this.isWeChat() == true ? 'wx' : '',
        openid: window.sessionStorage.getItem('openid'),
        mch_id: window.sessionStorage.getItem('mch_id'),
        url: encodeURIComponent(urls)
      };
      this.WXdata = await shopWechatPay2(data);
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: this.WXdata.appId, // 必填，公众号的唯一标识
        timestamp: this.WXdata.timeStamp, // 必填，生成签名的时间戳
        nonceStr: this.WXdata.nonceStr, // 必填，生成签名的随机串
        signature: this.WXdata.signature, // 必填，签名
        jsApiList: this.WXdata.jsApiList // 必填，需要使用的JS接口列表
      });

      this.WXdata.openids = this.openid;

      //  this.WXdata.prepay_id = this.WXdata.prepay_id;
      // this.WXdata.paySign = this.WXdata.paySign;
      //校验浏览对微信api是否支持
      this.verification();
    },
    //校验浏览对微信api是否支持
    verification() {
      if (typeof WeixinJSBridge == 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
        }
      } else {
        this.onBridgeReady();
      }
    },
    onBridgeReady() {
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: this.WXdata.appId, //公众号名称，由商户传入
          timeStamp: this.WXdata.timeStamp, //1970年以来的秒数
          nonceStr: this.WXdata.nonceStr, //随机串
          package: 'prepay_id=' + this.WXdata.prepay_id,
          signType: 'MD5', //微信签名方式：
          paySign: this.WXdata.paySign //微信签名
        },
        function (res) {
          if (res.err_msg == 'get_brand_wcpay_request:ok') {
            Toast.success('支付成功');
          } else if (res.err_msg == 'get_brand_wcpay_request:cancel') {
            Toast.fail('已取消');
          } else if (res.err_msg == 'get_brand_wcpay_request:fail') {
            Toast.fail('支付失败');
          }
        }
      );
    },
    //判断微信环境
    isWeChat() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
      } else {
        return false;
      }
    },
    againPay() {
      this.show = false;
    },
    //验证支付情况
    async completePay() {
      this.loading = true;
      checkOrder({ orderNo: this.$route.query.orderNo })
        .then((res) => {
          this.show = false;
          this.loading = false;
          Toast.success(res.msg);
        })
        .catch((err) => {
          let timer = setTimeout(() => {
            Toast.fail(err);
            this.loading = false;
            timer = null;
          }, 2000);
        });
    },
    //商品详情
    async getGoodDetail() {
      await getGoodDetail({ good_id: this.$route.query.good_id, nonce: window.sessionStorage.getItem('nonce') })
        .then((result) => {
          this.price = result.data.price;
          this.titleObj.title = result.data.title;
          this.priceObj.OriPrice = result.data.price;
          this.team_id = result.data.team_id;
          this.images = [result.data.img];
        })
        .catch((err) => {
          Toast(err);
        });
    },
    //支付
    async pay() {
      //记录点击
      // this.recordClick();
      // 检查是否达到点击限制
      // if (this.checkClickLimit()) {
      //   Toast.fail('请稍后重试');
      //   return;
      // }
      let data = {
        url: window.location.href,
        pay_type: this.payMethod,
        good_id: this.$route.query.good_id,
        nonce: window.sessionStorage.getItem('nonce'),
        team_id: window.sessionStorage.getItem('team'),
        sales_id: window.sessionStorage.getItem('sales_id'),
        openid: window.sessionStorage.getItem('openid'),
        mch_id: window.sessionStorage.getItem('mch_id'),
        isWeChat: this.isWeChat() == true ? 'wx' : ''
      };
      if (+this.payMethod == 1) {
        if (this.isWeChat()) {
          console.log('微信环境');
          if (!this.$route.query.code) {
            this.curPageUrl = decodeURIComponent(window.location.href);
            data.url = this.curPageUrl;
            getAppid2(data)
              .then((resp) => {
                //mch_type 1：微信支付 2:付宝 3:滴沥付 4:汇付 5:畅海
                if (+resp.mch_type == 5) {
                  window.location.href = resp.url;
                } else {
                  window.sessionStorage.setItem('appid', resp.appid);
                  window.sessionStorage.setItem('mch_id', resp.mch_id);
                  window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + resp.appid + '&response_type=code&scope=snsapi_base&state=STATE' + '&redirect_uri=' + this.curPageUrl + '#wechat_redirect';
                }
              })
              .catch((err) => {
                Toast(err);
              });
          } else {
            this.authCode = this.$route.query.code;
            this.getOpenid().then((res) => {
              console.log('getOpenid', typeof res);
              console.log('获取到的openid为', res.openid);
              this.openid = res.openid;
              window.sessionStorage.setItem('openid', res.openid);
              this.beforePay();
            });
          }
        } else {
          const result = await shopWechatPay2(data).catch((err) => {
            Toast(err);
          });
          window.location.href = result.url;
        }
      } else {
        const result = await getALiH5Pay(data).catch((err) => {
          Toast(err);
        });
        window.location.href = result.url;
      }
    },
    changePay(value) {
      this.payMethod = value;
    },
    handleTab(index) {
      console.log(index);
      if (index == 1) {
        // 获取要滚动到的元素（假设该元素有一个唯一的ID）
        var element = document.getElementById('detail');

        // 检查该元素是否存在
        if (element) {
          // 计算该元素相对于文档顶部的距离
          var offsetTop = element.offsetTop;

          // 滚动到该位置
          window.scrollTo({
            top: offsetTop,
            behavior: 'smooth' // 平滑滚动（可选）
          });
        }
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // 平滑滚动（可选）
        });
      }
    }
  }
};
</script>
<style scoped lang="less">
.img-back {
  width: 30px;
  height: 30px;
  position: fixed;
  z-index: 1000;
  top: 11px;
  left: 15px;
}
.img-menu-box {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 15px;
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  .img-menu {
    width: 30px;
    height: 30px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 22px;
  }
}
.menu-dialog {
  position: fixed;
  right: 10px;
  width: 132px;
  background: #fff;
  box-shadow: 0 0 8px rgb(0 0 0 / 8%);
  z-index: 310;
  border-radius: 7px;
  top: 55px;
  .item {
    width: 100%;
    height: 46px;
    line-height: 46px;
    padding: 0 11px 0;
    box-sizing: border-box;
    border-bottom: #eee;
    font-size: 15px;
    color: #333;
    position: relative;
    display: flex;
    align-items: center;
    font-family: 'SourceHanSansCNRegular';
    .title::before {
      content: '';
      position: absolute;
      width: 90px;
      height: 1px;
      background-color: #eee;
      bottom: 0;
      right: 0;
    }
    .img-icon {
      width: 17px;
      height: 17px;
      margin-right: 10px;
    }
  }
}
.menu-dialog::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #fff;
  position: absolute;
  top: -11px;
  right: 17px;
  border-bottom-color: #fff;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.loading-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
}

.loading-dialog .dialog-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loading-dialog .dialog-content {
  position: relative;
  z-index: 10000;
  text-align: center;
  padding: 0.4rem 1.5rem;
}
.dialog-content {
  width: 80%;
  background-color: #fff;
  border-radius: 10px;
  .p2 {
    color: #ff2627;
  }
  .p1,
  .p2 {
    border-bottom: 1px solid #e7e7e7;
  }
}
.dialog-content p {
  padding: 15px 15px;
  font-size: 16px;
  text-align: center;
}
</style>
