<template>
  <div class="coupon-box">
    <div class="left">
      <span>优惠券：</span>
      <div class="item" v-for="(item, index) in couponObj" :key="index">满 {{ item.overMoney }} 减 {{ item.reduceMoney }}</div>
    </div>
    <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/7.png" alt="" class="img-right-arrow" />
  </div>
</template>

<script>
export default {
  props: { couponObj: Array },
  data() {
    return {};
  },
  methods: {}
};
</script>
<style scoped lang="less">
.coupon-box {
  background: #ffffff;
  padding: 0 16px;
  border-top: 0.5px solid #f5f5f5;
  height: 44px;
  font-size: 14px;
  color: #82848f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .img-right-arrow {
    width: 15px;
    height: 15px;
  }
  .left {
    width: 298px;
    height: 44px;
    line-height: 44px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    flex-wrap: wrap;
    .item {
      height: 22px;
      padding: 0 11px;
      border: 0.5px solid var(--view-minorColor);
      color: var(--view-theme);
      font-size: 13px;
      line-height: 22px;
      position: relative;
      margin: 10px 0 10px 8px;
    }
    .item::before {
      content: ' ';
      position: absolute;
      width: 3px;
      height: 5px;
      border-radius: 0 3px 3px 0;
      border: 0.5px solid var(--view-minorColor);
      background-color: #fff;
      bottom: 50%;
      left: -1px;
      margin-bottom: -3px;
      border-left-color: #fff;
    }
    .item::after {
      content: ' ';
      position: absolute;
      width: 3px;
      height: 5px;
      border-radius: 3px 0 0 3px;
      border: 0.5px solid var(--view-minorColor);
      background-color: #fff;
      right: -1px;
      bottom: 50%;
      margin-bottom: -3px;
      border-right-color: #fff;
    }
  }
}
</style>
