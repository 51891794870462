<template>
  <div class="pay-container">
    <div v-if="!isWeChat()">
      <div class="pay-header">
        <div>
          付款给企业
          <div class="company-name">({{ conBrand }}收银台)</div>
        </div>
      </div>
      <div class="pay-main">
        <div class="pay-money-title">付款金额</div>
        <div class="pay-input-wrapper">
          <span class="pay-icon">￥</span>
          <div class="pay-money" v-if="inputValue">{{ inputValue }}</div>
          <div class="pay-cursor" v-if="!inputValue"></div>
        </div>
      </div>
      <van-number-keyboard :show="true" theme="custom" extra-key="." close-button-text="完成" @input="onInput" @delete="onDelete" v-model="inputValue" @close="handleClose" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Toast } from 'vant';
import { h5Pay, getBrand } from '@/api/cashDesk';
Vue.use(Toast);
export default {
  props: {},
  data() {
    return {
      team: '', //团队名称
      username: '', //销售名称
      conBrand: '芙悦', //品牌名
      mchId: '1', //支付商户号id
      sales_id: '', //销售id
      inputValue: ''
    };
  },
  computed: {},
  created() {
    //new VConsole();
  },
  async mounted() {
    if (this.isWeChat()) {
      Toast('请从支付宝打开');
      return;
    } else {
      const brands = await getBrand();
      this.team = this.$route.query.team;
      this.username = this.$route.query.username;
      this.conBrand = brands.data.filter((item) => +item.id == +this.$route.query.conBrand)[0].name;
      this.mchId = this.$route.query.mchId;
      this.sales_id = this.$route.query.sales_id;
    }
  },
  watch: {},
  methods: {
    //判断微信环境
    isWeChat() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
      } else {
        return false;
      }
    },
    onDelete() {
      console.log('删除');
      this.inputValue = this.inputValue.slice(0, -1);
    },
    onInput(value) {
      this.inputValue = String(value);
    },
    async handleClose() {
      console.log('完成', this.inputValue);
      const data = {
        team: this.team,
        username: this.username,
        conBrand: this.conBrand,
        mchId: this.mchId,
        price: this.inputValue,
        sales_id: this.sales_id
      };
      const result = await h5Pay(data);
      window.location.href = result.data.url;
    }
  },
  components: {}
};
</script>

<style scoped lang="less">
.pay-container {
  padding: 0 31px;
  height: 100vh;
  background: #ffffff;
}
.pay-header {
  height: 130px;
  display: flex;
  align-items: center;
  .company-name {
    font-size: 12px;
    margin-top: 6px;
    color: #909399;
  }
}
.pay-money-title {
  font-size: 14px;
  text-align: left;
}
.pay-input-wrapper {
  margin-top: 6px;
  position: relative;
  display: flex;
  height: 50px;
  line-height: 50px;
  align-items: center;
}
.pay-input-wrapper:after {
  content: ' ';
  position: absolute;
  left: -50%;
  right: -50%;
  bottom: 0;
  border-bottom: 1px solid rgb(235, 237, 240);
  transform: scaleX(0.5);
}
.pay-icon,
.pay-money {
  font-size: 18px;
  font-weight: bold;
}
.pay-money {
  margin-right: 2px;
}
.pay-cursor {
  width: 3px;
  height: 33px;
  background: #1aad19;
  border-radius: 3px;
  animation: twinkling 1.5s infinite;
}
@keyframes twinkling {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
</style>
