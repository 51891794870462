<template>
  <div id="detail" class="product-box">
    <p class="title">产品介绍</p>
    <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/4.png" alt="" class="img-pro" />
    <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/8.png" alt="" class="img-pro" />
    <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/9.png" alt="" class="img-pro" />
    <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/10.png" alt="" class="img-pro" />
    <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/11.png" alt="" class="img-pro" />
    <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/12.png" alt="" class="img-pro" />
    <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/13.png" alt="" class="img-pro" />
    <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/14.png" alt="" class="img-pro" />
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {},
  components: {}
};
</script>
<style scoped lang="less">
.product-box {
  position: relative;
  margin-top: 11px;
  width: 100%;
  padding-bottom: 55px;
  overflow: hidden;
  .title {
    font-size: 16px;
    color: #333;
    height: 47px;
    width: 100%;
    background-color: #fff;
    text-align: center;
    line-height: 47px;
  }
  .img-pro {
    display: block;
    width: 100%;
  }
}
</style>
