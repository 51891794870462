import { render, staticRenderFns } from "./Swipe.vue?vue&type=template&id=4f3f6f92&scoped=true&"
import script from "./Swipe.vue?vue&type=script&lang=js&"
export * from "./Swipe.vue?vue&type=script&lang=js&"
import style0 from "./Swipe.vue?vue&type=style&index=0&id=4f3f6f92&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f3f6f92",
  null
  
)

export default component.exports