import request from '@/utils/request';

//登录接口
export function login(data) {
  return request({
    url: '/admin/login',
    method: 'post',
    data
  });
}

//获取用户信息
export function getInfo() {
  return request({
    url: '/admin/getUserInfo',
    method: 'post'
  });
}

//退出登录接口
export function logout() {
  return request({
    url: '/admin/loginOut',
    method: 'post'
  });
}

//获取路由表接口
export function getMoveRouter() {
  return request({
    url: '/admin/getMoveRouter',
    method: 'post'
  });
}
//获取所有路由
export function getAllRouter(data) {
  return request({
    url: '/admin/getAllRouter',
    method: 'post',
    data
  });
}
