<template>
  <div class="stock-box">
    <!-- <span class="delete-line">划线价 : ￥{{ stockObj.delLinePrice }}</span> -->
    <span>库存 : {{ stockObj.stock }} 个</span>
    <span>销量 : {{ stockObj.salesNum }} 个</span>
  </div>
</template>

<script>
export default {
  props: { stockObj: Object },
  data() {
    return {};
  },
  methods: {}
};
</script>
<style scoped lang="less">
.stock-box {
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color: #82848f;
  padding-top: 12px;
  padding-bottom: 11px;
  padding-left: 16px;
  padding-right: 16px;
  .delete-line {
    text-decoration: line-through;
  }
}
</style>
