import request from '@/utils/request';
//h5支付  (收银台)
export function h5Pay(data) {
  return request({
    url: '/api/fyCashierAliH5Pay',
    method: 'post',
    data
  });
}
//获取品牌列表
export function getBrand() {
  return request({
    url: '/api/getBrand',
    method: 'post'
  });
}
