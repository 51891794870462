<template>
  <div class="specific-box">
    <div class="flex">
      <div class="top-box">
        <div class="left">
          <span class="attr-txt">已选择：</span>
          <span class="atterTxt line1">丰韵霜1支装</span>
        </div>
      </div>
      <!-- <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/7.png" alt="" class="img-right-arrow" /> -->
    </div>
    <!-- <div class="bottom-box">
      <div class="left">
        <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/1.png" class="img-smart" />
        <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/1.png" class="img-smart" />
        <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/1.png" class="img-smart" />
        <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/1.png" class="img-smart" />
      </div>
      <div class="right">共5 种规格可选</div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style scoped lang="less">
.specific-box {
  padding: 5px 16px;
  background-color: #fff;
  padding: 0 16px;
  font-size: 14px;
  color: #82848f;
  margin-top: 11px;
  min-height: 44px;
  padding-bottom: 10px;
  .img-right-arrow {
    width: 15px;
    height: 15px;
  }
  .flex {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .top-box {
      display: flex;
      align-items: center;
      width: 90%;
      .atterTxt {
        font-size: 15px;
        color: #282828;
      }
    }
  }
  .bottom-box {
    margin-top: 7px;
    padding-left: 58px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-right: 23px;
    .left {
      display: flex;
      .img-smart {
        width: 32px;
        height: 32px;
        margin-right: 6px;
      }
    }
    .right {
      height: 33px;
      line-height: 33px;
      box-sizing: border-box;
      background: #eee;
      padding: 0 5px;
      border-radius: 4px;
      text-align: center;
    }
  }
}
</style>
