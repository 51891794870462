<template>
  <div class="nav-box">
    <div class="nav-tab">
      <div class="tab" v-for="(item, index) in tabList" :key="index" @click="tabClick(index)">
        <span class="title" :class="{ active: index == curIndex }">{{ item }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      active: 0,
      tabList: ['商品', '详情'],
      curIndex: 0
    };
  },
  methods: {
    tabClick(index) {
      this.curIndex = index;
      this.$emit('handleTab', index);
    }
  },
  components: {}
};
</script>
<style scoped lang="less">
.nav-box {
  position: sticky;
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 52px;
  opacity: 1;
  font-size: 16px;
  .nav-tab {
    display: flex;
    justify-content: center;
    .tab {
      height: 52px;
      font-size: 16px;
      color: #050505;
      display: flex;
      align-items: center;
      .title {
        position: relative;
        margin: 0 13px;
      }
      .title.active:before {
        position: absolute;
        width: 33px;
        height: 2px;
        background-repeat: no-repeat;
        content: '';
        background-color: var(--view-theme);
        bottom: -5px;
        left: 50%;
        margin-left: -15px;
      }
    }
  }
}
</style>
