import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import VueBus from 'vue-bus';
import 'element-ui/lib/theme-chalk/index.css';
import 'normalize.css/normalize.css';
import './assets/css/reset.css';
import './assets/css/common.less';
import './assets/css/fonts.css';
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
Vue.use(ElementUI);
Vue.use(VueBus);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
