<template>
  <div class="title-box">
    <p class="title">{{ titleObj.title }}</p>
    <p class="limit-num" v-if="titleObj.limitNum > 0">永久限购{{ titleObj.limitNum }}个</p>
  </div>
</template>

<script>
export default {
  props: { titleObj: Object },
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
<style scoped lang="less">
.title-box {
  padding: 12px 16px 12px 16px;
  background: #ffffff;
  .title {
    font-size: 16px;
    word-break: break-all;
    min-height: 24px;
    text-align: left;
    line-height: 20px;
    color: #333;
    font-weight: 700;
  }
  .limit-num {
    text-align: left;
    font-size: 8px;
    margin: 5px 0;
    color: red;
  }
}
</style>
