<template>
  <div class="payMehtod-box">
    <p class="method-title">支付方式</p>
    <van-radio-group v-model="radio" @change="$emit('changePay', radio)">
      <div class="detail">
        <div class="left">
          <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/20.png" alt="" class="img-weixin img-icon" />
          <div class="text-box">
            <p class="title">微信支付</p>
            <p class="desc">使用微信快捷支付</p>
          </div>
        </div>
        <van-radio name="1" checked-color="#e93323"></van-radio>
      </div>
      <div class="detail">
        <div class="left">
          <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/21.png" alt="" class="img-zhifubao img-icon" />
          <div class="text-box">
            <p class="title">支付宝支付</p>
            <p class="desc">使用支付宝支付</p>
          </div>
        </div>
        <van-radio name="2" checked-color="#e93323"></van-radio>
      </div>
    </van-radio-group>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      radio: '1' //1 微信支付  2 支付宝支付
    };
  },
  created() {},
  mounted() {},
  methods: {},
  components: {}
};
</script>
<style scoped lang="less">
.payMehtod-box {
  background: #ffffff;
  padding: 0 16px;
  padding-bottom: 55px;
  .method-title {
    text-align: left;
    font-size: 14px;
    padding: 10px 0;
  }
  .detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid #f7f7f7;
    .left {
      display: flex;
      align-items: center;
      .text-box {
        p {
          text-align: left;
          line-height: 20px;
        }
        .title {
          font-size: 16px;
          color: #4d4d4a;
        }
        .desc {
          font-size: 14px;
        }
      }
    }
    .img-icon {
      width: 25px;
      height: 25px;
      margin-right: 20px;
    }
  }
}
</style>
