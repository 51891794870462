<template>
  <div><van-button type="info" @click="toPage">跳转详情</van-button></div>
</template>

<style scoped lang="less"></style>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    toPage() {
      this.$router.push({
        path: 'goodsDetail',
        query: {
          id: 3,
          type: 3
        }
      });
    }
  },
  components: {}
};
</script>
