<template>
  <div class="footer-box">
    <!-- <div class="item">
      <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/15.png" class="img-icon" />
      <p class="label">首页</p>
    </div>
    <div class="item">
      <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/16.png" class="img-icon" />
      <p class="label">收藏</p>
    </div>
    <div class="item">
      <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/17.png" class="img-icon" />
      <p class="label">购物车</p>
    </div> -->
    <div class="btn-box">
      <!-- <span class="btn joinCart">加入购物车</span> -->
      <span class="btn buy" @click="$emit('pay')">确认支付</span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
<style scoped lang="less">
.footer-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 11px 5px 16px;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: hsla(0, 0%, 100%, 0.85);
  .item {
    font-size: 9px;
    color: #666;
    .label {
      text-align: center;
    }
    .img-icon {
      width: 23px;
      height: 23px;
    }
  }
  .btn-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 41px;
    justify-content: center;
    .btn {
      width: 80%;
      text-align: center;
      line-height: 41px;
      color: #fff;
      font-size: 15px;
    }
    .joinCart {
      border-radius: 27px 0 0 27px;
      background-color: var(--view-bntColor);
    }
    .buy {
      border-radius: 20px;
      background-color: var(--view-theme);
    }
  }
}
</style>
