import request from '@/utils/request';

//微信支付接口
export function shopWechatPay(data) {
  return request({
    url: '/api/wxPay',
    method: 'post',
    data
  });
}
//支付接口
export function shopWechatPay2(data) {
  return request({
    url: '/api/HuiFuPay',
    method: 'post',
    data
  });
}
//支付宝支付接口
export function getALiH5Pay(data) {
  return request({
    url: '/api/zfbPay',
    method: 'post',
    data
  });
}
//商品详情
export function getGoodDetail(data) {
  return request({
    url: '/api/getGoodDetail',
    method: 'post',
    data
  });
}
//验证支付情况
export function checkOrder(data) {
  return request({
    url: '/api/checkOrder',
    method: 'post',
    data
  });
}
//获取支付的配置接口
export function getconfig(data) {
  return request({
    url: '/api/getconfig',
    method: 'post',
    data
  });
}
//支付接口
export function wxJsApiPay(data) {
  return request({
    url: '/api/wxJsApiPay',
    method: 'post',
    data
  });
}
//获取appId
export function getAppid(data) {
  return request({
    url: '/api/silenceAuth',
    method: 'post',
    data
  });
}
//测试接口  获取appId
export function getAppid2(data) {
  return request({
    url: '/api/silenceAuth2',
    method: 'post',
    data
  });
}
