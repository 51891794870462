import axios from 'axios';
import { MessageBox, Message } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';
import errMessage from './errMessage';
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000,
  withCredentials: true
});

service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (+res.code != 10000) {
      return Promise.reject(res.msg || '系统错误，请联系管理员');
    } else {
      return res;
    }
  },
  (error) => {
    console.log('err' + error);
    errMessage(error.message);
    return Promise.reject(error);
  }
);

export default service;
