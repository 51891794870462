<template>
  <div class="menu-dialog" v-if="showMenuDialog">
    <div class="item">
      <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/15.png" class="img-icon" />
      <span class="title">首页</span>
    </div>
    <div class="item">
      <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/18.png" class="img-icon" />
      <span class="title">搜索</span>
    </div>
    <div class="item">
      <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/17.png" class="img-icon" />
      <span class="title">购物车</span>
    </div>
    <div class="item">
      <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/16.png" class="img-icon" />
      <span class="title">我的搜藏</span>
    </div>
    <div class="item">
      <img src="https://cdn.ihuiniu.cn/h5-qmy-shop/19.png" class="img-icon" />
      <span class="title">个人中心</span>
    </div>
  </div>
</template>

<script>
export default {
  props: { showMenuDialog: Boolean },
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
<style scoped lang="less">
.menu-dialog {
  position: fixed;
  right: 10px;
  width: 132px;
  background: #fff;
  box-shadow: 0 0 8px rgb(0 0 0 / 8%);
  z-index: 310;
  border-radius: 7px;
  top: 55px;
  .item {
    width: 100%;
    height: 46px;
    line-height: 46px;
    padding: 0 11px 0;
    box-sizing: border-box;
    border-bottom: #eee;
    font-size: 15px;
    color: #333;
    position: relative;
    display: flex;
    align-items: center;
    font-family: 'SourceHanSansCNRegular';
    .title::before {
      content: '';
      position: absolute;
      width: 90px;
      height: 1px;
      background-color: #eee;
      bottom: 0;
      right: 0;
    }
    .img-icon {
      width: 17px;
      height: 17px;
      margin-right: 10px;
    }
  }
}
.menu-dialog::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #fff;
  position: absolute;
  top: -11px;
  right: 17px;
  border-bottom-color: #fff;
}
</style>
