import { login, logout, getInfo, getMoveRouter } from '@/api';
import { getToken, setToken, removeToken } from '@/utils/auth';
//import { resetRouter } from '@/router';

const getDefaultState = () => {
  return {
    token: '',
    name: '',
    avatar: '',
    menus: '', //存放路由表的容器
    loginUserId: '' //登录用户的id
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_LOGIN_USER_ID: (state, loginUserId) => {
    state.loginUserId = loginUserId;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_MENU: (state, menus) => {
    state.menus = menus;
  }
};

const actions = {
  //用户登录
  login({ commit }, userInfo) {
    const { username, password } = userInfo;
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password })
        .then((response) => {
          const { data } = response;
          commit('SET_TOKEN', data.token);
          setToken(data.token);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //获取用户信息
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then((response) => {
          const { data } = response;
          const { username, icon, id } = data.data;
          commit('SET_NAME', username);
          commit('SET_AVATAR', icon);
          commit('SET_LOGIN_USER_ID', id);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  //获取动态菜单
  getMoveRouter({ commit, state }) {
    return new Promise((resolve, reject) => {
      getMoveRouter()
        .then((response) => {
          const menus = response.data.data;
          //如果需要404 页面，请在此处添加
          menus.push(
            {
              path: '/404',
              component: '404',
              hidden: true
            },
            {
              path: '*',
              redirect: '/404',
              hidden: true
            }
          );
          commit('SET_MENU', menus); //把name 保存到vuex中
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //用户退出登录
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          removeToken();
          //  resetRouter();
          commit('RESET_STATE');
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      removeToken();
      commit('RESET_STATE');
      resolve();
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
